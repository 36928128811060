<template>
    <h6 class="o-search__amend-title">
        Number of nights
    </h6>

    <!-- Dropdown if no allowedDays -->
    <div v-if="allowedDays.length === 0" class="o-search__nights-dropdown">
        <label for="nights-select">Select Nights:</label>
        <select id="nights-select" v-model="search.data.selected_nights" @change="availabilityRules">
            <option v-for="night in defaultNightsRange" :key="night" :value="night">
                {{ night }}
            </option>
        </select>
    </div>

    <!-- Radio buttons if allowedDays exist -->
    <div v-else class="o-search__nights">
        <div class="o-search__night" v-for="night in allowedDays" :key="night">
            <label>
                <input
                    type="radio"
                    :value="night"
                    @change="availabilityRules"
                    v-model="search.data.selected_nights" />{{ night }}
            </label>
        </div>
    </div>

    <!-- Plus/Minus 7 Days Checkbox Section -->
    <div class="o-search__days-adjust">
        <label class="flex items-center space-x-2">
            <input 
                type="checkbox" 
                :checked="search.data.selected_flexible_dates === 7"
                @change="toggleFlexibleDates"
                class="mr-2"
            />
            I'm flexible +/- 7 days
        </label>
    </div>

    <!-- Calendar/Range Section -->
    <div class="c-calendar" v-if="search.data.selected_nights">
        <div class="c-calendar__title">Select Dates</div>
        <Suspense>
        <template #default>
            <VueDatePicker
            :range="{ autoRange: search.data.selected_nights }"
            :inline="true"
            :min-date="tomorrow"
            :auto-apply="true"
            :enable-time-picker="false"
            :month-name-format="'long'"
            :disabled-week-days="disabledWeekDays"
            :month-change-on-scroll="false"
            :day-names="['M', 'T', 'W', 'T', 'F', 'S', 'S']"
            @update-month-year="onMonthChange"
            :enable-year-picker="false"
            v-model="search.data.selected_date"
            />
        </template>

        <template #fallback>
            <GlobalLoading />
        </template>
    </Suspense>

    </div>
</template>

<style scoped>
/* Override the default calendar cell styles */

/* Hide the previous month button */
:deep([data-dp-element="action-prev"]) {
  display: none !important;
}
/* Show previous button when we are NOT in the current month */
.show-prev :deep([data-dp-element="action-prev"]) {
  display: block !important;
}

:deep(.dp__overlay_cell_active){
  background: #ffcf6f;
}

:deep(.dp__month_year_wrap [data-dp-element="overlay-year"]) {
    pointer-events: none;
    cursor: default;
}



</style>

<script setup>
// Register only in component
const VueDatePicker = defineAsyncComponent(async () => {
  await import('@vuepic/vue-datepicker/dist/main.css')
  return (await import('@vuepic/vue-datepicker')).default
})


const search = useSearchStore();

const defaultNightsRange = computed(() => {
    const baseRange = Array.from({ length: 13 }, (_, i) => i + 2); // [2, 3, ..., 14]
    if (!exceptionalMinNights.value) return baseRange;
    return baseRange.filter(n => n >= exceptionalMinNights.value);
});


const exceptionalMinNights = computed(() => {
    const selectedDate = search.data.selected_date;
    const minNightRules = search.data.min_night_rules;

    if (!minNightRules || !minNightRules.exceptional_search_rules) return null;

    const rules = minNightRules.exceptional_search_rules.min_night_stay || [];

    if (!selectedDate || !Array.isArray(rules)) return null;

    const selectedStartDate = Array.isArray(selectedDate) ? selectedDate[0] : selectedDate;
    const selected = new Date(selectedStartDate);
    const formatted = selected.toLocaleDateString('en-GB'); // e.g., 18/04/2025

    const match = rules.find(rule => rule.date === formatted);
    return match ? parseInt(match.min_nights) : null;
});


const availabilityRules = () => {
    search.data.selected_date = []
}

const onMonthChange = ({ month, year }) => {
    console.log("Clicked month:", month, "year:", year);
    updatePrevButtonVisibility(month + 1, year);
}

const updatePrevButtonVisibility = (month, year) => {
  const today = new Date();
  const currentMonth = today.getMonth() + 1; // 1-based index
  const currentYear = today.getFullYear();

  // Check if we are in a future month
  const isFutureMonth = year > currentYear || (year === currentYear && month > currentMonth);

  console.log(isFutureMonth)

  // Toggle a class on the date picker to control button visibility
  const datePicker = document.querySelector(".c-calendar");
  if (datePicker) {
    if (isFutureMonth) {
      datePicker.classList.add("show-prev"); // Show back button
    } else {
      datePicker.classList.remove("show-prev"); // Hide back button
    }
  }
};

// Allowed days (flattened number_of_nights array from availability rules)
const allowedDays = computed(() => {
    return search.availability_rules.rules.length
        ? [...new Set(search.availability_rules.rules.flatMap(rule => rule.number_of_nights))]
        : [];
});

const tomorrow = computed(() => {
    const date = new Date();
    date.setDate(date.getDate() + 1); // Move to tomorrow
    return date;
});

const toggleFlexibleDates = (event) => {
    search.data.selected_flexible_dates = event.target.checked ? 7 : 0;
};

// Compute disabled week days for vue-date-picker
const disabledWeekDays = computed(() => {
    const availableDays = search.availability_rules?.selectable_days || [];
    return getNonAvailableDayIndices(availableDays);
});

function getNonAvailableDayIndices(availableDays) {
    if (!availableDays.length) return []; // Avoid breaking the calendar if no days are available
    const allDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    return allDays
        .map((day, index) => (availableDays.includes(day) ? null : index))
        .filter(index => index !== null);
}

onMounted(async () => {
    await search.fetchMinNightRules()
})
</script>
